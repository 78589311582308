@import "../../static/scss/colors.scss";
@import "../../static/scss/App.scss";


.EnterpriseWorkspace {
    position: relative;
    background-color: $figma-shades-0;
    $margin: 30px;
    $margin-left: $margin + 17;
    margin: $margin;
    margin-left: $margin-left;
    height: calc(100% - (2 * $margin));
    border-radius: 16px;
    min-width: 1200px;
        
    .custom-mt-29 {
        margin-top: 29px;
    }

    .custom-mt-30 {
        margin-top: 30px;
    }
    
    .custom-mt-33 {
        margin-top: 33px;
    }

    .custom-w-100 {
        width: 100px;
    }

    .MuiBox-root {
        padding: 0;
    }

    .tabs-box {
        position: relative;
        margin: 0 120px;
        button.MuiTab-root {
            text-transform: none;
        }
        .tab {
            @extend .figma-p2;
            transition: font-weight 100ms;
            font-weight: 400;
            width: 50%;
            text-align: center !important;
            padding: 12px 16px;
            cursor: pointer;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            color: rgba(0,0,0,0.6);
            &.selected {
                color: $figma-primary-600;
                font-weight: 600;
            }
        }
        .highlight-line {
            position: absolute;
            height: 2px;
            border: 1px solid $figma-primary-600;
            width: 50%;
            bottom: 0;
            transition: left 300ms;
            &.selected-tab-0 {
                left: 0;
            }
            &.selected-tab-1 {
                left: 50%;
            }

        }
    }

    .tight-column {
        max-width: 680px;
    }

    .inner-container {
        padding: 0 120px;
        overflow: auto;
    }

    $paginator-height: 60px;

    .main-container {
        height: calc(100% - $paginator-height);
    }

    .big-card {
        border: 1px solid $figma-neutral-400;
        border-radius: 10px;
        // height: 178px;
        div.col {
            overflow-x: auto;
            &:not(:last-child) {
                border-right: 1px solid $figma-neutral-400;
            }
            svg {
                margin-left: 8px;
                $icon-size: 20px;
                min-width: $icon-size;
                width: $icon-size;
                max-width: $icon-size;
                min-height: $icon-size;
                height: $icon-size;
                max-height: $icon-size;
                &.small{
                    $icon-size: 16px;
                    min-width: $icon-size;
                    width: $icon-size;
                    max-width: $icon-size;
                    min-height: $icon-size;
                    height: $icon-size;
                    max-height: $icon-size;
                }
            }
            .badge {
                background-color: $figma-neutral-50 !important;
                color: $figma-neutral-600;
                border: 1px solid $figma-neutral-200;
                .border-right-line {
                    border-right: 1px solid $figma-neutral-200;
                }
            }
        }
    }

    .add-member-form {
        .new-member-email-group{
            width: 35%;
            max-width: 450px;
        }

        .ai-module-select{
            min-width: 200px;
        }
    }

    .edit-member-form {
        margin-left: 24px;
        margin-top: 20px;
        .ai-module-select{
            min-width: 200px;
        }

        .custom-mt-6{
            margin-top: 6px;
        }
    }

    .ai-module-number {
        color: $figma-neutral-400;
        font-size: 0.85rem
    }

    .paginator {
        position: absolute;
        width: 100%;
        height: $paginator-height;
        bottom: 0;
        padding: 0 144px;
        border-top: 1px solid $figma-neutral-300;
    }

    .workspace-name-form {
        min-width: 300px;
        max-width: 700px;
    }

    .table {
        & > :not(:first-child) {
            border-top: 1px solid $figma-neutral-400;
        }
        tr{
            &:first-child {
                border-color: $figma-neutral-400;
            }
            height: 68px;
            th {
                vertical-align: middle;
                &:not(:first-child):not(:last-child) {
                    div:first-child {
                        justify-content: center;
                        text-align: center;
                    }
                }
            }
            td {
                border-bottom-color: $figma-neutral-300;
                vertical-align: middle;
                &:not(:first-child) {
                    text-align: center;
                }
            }
        }
    }

    .gray-card {
        border: 1px solid $figma-primary-50;
        background-color: $figma-neutral-50;
        border-radius: 5px;
    }

    #change-workspace-name-loader {
        width: 100px;
    }
}
