@import 'react-toastify/dist/ReactToastify.css';

:root {
    --toastify-color-success: #10B981; //figma-success-500
    --toastify-color-warning: #F59E0B; //figma-warning-500
    --toastify-color-error: #EF4444; //figma-error-500
    --toastify-toast-width: 360px;
}

.Toastify__close-button {
    opacity: 1;
}