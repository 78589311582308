@import "../../static/scss/colors.scss";


.CaseList {
    background-color: $figma-shades-0;
    $margin: 30px;
    $margin-left: $margin + 17;
    margin: $margin;
    margin-left: $margin-left;
    height: calc(100% - (2 * $margin));
    border-radius: 16px;

    .preview-image {
        height: 45px;
    }

    tr:not(.results-row) {
        td:first-child {
            position: relative;
            img {
                margin: auto !important;
                top: 0;
                bottom: 0;
                position: absolute;
            }
        }
    }

    .delete-case-button {
        height: 37px;
        width: 37px;
    }

    .text-neutral-400{
        color: $figma-neutral-400
    }
}

#delete-case-modal {
    a {
        text-decoration: none;
        color: $figma-primary-600;
        font-weight: bold;
        .card {
            background-color: $figma-secondary-300;
            height: 200px;
        }
    }
    .trash-circle {
        height: 48px;
        width: 48px;
        border-radius: 50%;
    }
}