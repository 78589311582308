@import "../../../static/scss/colors.scss";


.CustomSegmentationsTable {
    overflow-y: auto;

    th {
        background-color: $figma-shades-0 !important;
        color: $figma-neutral-500 !important;
        font-weight: normal;
        &:not(:first-child)>div {
            justify-content: center;
        }
    }

    tr:not(.results-row) {
        td {
            color: $figma-neutral-900 !important;
            vertical-align: middle;
            height: 60px;
        }
    }

    tr.results-row {
        position: sticky;
        bottom: -1px;
        z-index: 1;

        td {
            padding: 18px 10px 17px 10px;
            background-color: $figma-shades-0 !important;
            color: $figma-neutral-500 !important;
        }
    }

    table thead th {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .table> :not(:first-child) {
        border-top: none;
    }

    & + .paginator {
        margin-bottom: -48px;
    }
}