@import "../../static/scss/colors.scss";


.CaseDetail {
    background-color: $figma-shades-0;
    $margin: 30px;
    $margin-left: $margin + 17;
    margin: $margin;
    margin-left: $margin-left;
    height: calc(100% - (2 * $margin));
    border-radius: 16px;

    .main-row {
        overflow-x: auto;
    }

    .leftColumn {
        height: 100%;
        overflow-y: auto;
        border-right: 1px solid $figma-neutral-200;
        min-width: 370px;
    }

    .rightColumn {
        height: 100%;
        overflow-y: auto;
        min-width: 370px;
    }

    .nonfield-errors {
        color: $figma-error-500;
        font-size: .875em;
    }

    input[fieldtype=opacity] {
        min-width: 75px;
        max-width: 75px;

        &:disabled {
            min-width: 75px;
            max-width: 75px;
        }
    }

    .new-layer-button {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 40%;
        min-width: 150px;
        max-width: 250px;

        svg {
            margin-right: 12px;
        }
    }

    .fullSpinner {
        width: 100px;
        height: 100px;
    }

    .progressbar-container {
        height: 68px;
    }

    #delete-case-modal {
        a {
            text-decoration: none;
            color: $figma-primary-600;
            font-weight: bold;

            .card {
                background-color: $figma-secondary-300;
                height: 200px;
            }
        }

        .trash-circle {
            height: 48px;
            width: 48px;
            border-radius: 50%;
        }
    }

    .form-control:disabled,
    .form-select:disabled,
    .form-control[readonly] {
        background-color: #fff;
        background-image: none;
        color: #94A3B8 !important;
    }

    .star-icon {
        // color: $figma-primary-600;

        &.clickable {
            cursor: pointer;
        }

        &.filled {
            fill: currentColor;
        }
    }

    .profile-picture {
        height: 32px;
        width: 32px;
        max-width: none;
    }

    .sharing-thumbnails-container {
        height: 32px;
        position: relative;

        .shared-picture-container {
            position: absolute;
            right: 0;
            background-color: $figma-shades-0;
            border-radius: 50%;
            height: 32px;
            width: 32px;
            padding: 2px;

            .shared-picture {
                height: 28px;
                width: 28px;
                max-width: none;

                &.number {
                    background-color: $figma-primary-600;
                    color: $figma-shades-0;
                    border-radius: 50%;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 14px;
                }
            }
        }

        .z-index-shared-with-0 {
            z-index: 6;
            margin-right: 0px;
        }

        .z-index-shared-with-1 {
            z-index: 5;
            margin-right: 20px;
        }

        .z-index-shared-with-2 {
            z-index: 4;
            margin-right: 40px;
        }

        .z-index-shared-with-3 {
            z-index: 3;
            margin-right: 60px;
        }

        .z-index-shared-with-4 {
            z-index: 2;
            margin-right: 80px;
        }

        .z-index-shared-with-number {
            z-index: 6;
        }
    }

    .preview-card {
        border: 1px solid $figma-neutral-300
    }

    .delete-case-button,
    .edit-case-button {
        height: 43px;
        width: 43px;
    }

    .save-changes-button {
        height: 43px;
        width: 131px;
    }

    .cancel-changes-button {
        height: 43px;
        width: 84px;
    }

    .open-case-button {
        height: 43px;
        width: 131px;
    }

    .preview-thumbnail {
        height: 80px;
        width: 80px;
        border-radius: 5px;
        display: inline-block;
        border: 1px solid #CBD5E1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;

        &.selected {
            border: 2px solid #0F6E8C;
            position: relative;

            .greenDot {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: $figma-secondary-300;
                position: absolute;
                top: 4px;
                right: 4px;
            }
        }
    }

    .fit-content {
        width: fit-content;
    }
}

#share-modal {
    .profile-picture {
        height: 41px;
        width: 41px;
        max-width: none;
    }

    div.hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    div.hide-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .search-box-container {
        position: relative;

        .search-box {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            position: absolute;
            max-height: 250px;
            overflow-y: auto;
        }

        .line-height-66 {
            line-height: 66px;
        }
    }

    input {
        &:not(.focused) {
            padding-left: calc(1rem + 36px);
        }

        &.focused {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .fi-user-plus-container {
        position: absolute;
        left: calc(1rem + 16px);
        display: inline;
        height: 45px;
    }

    .result-row {
        cursor: pointer;
    }

    .result-row:hover {
        background-color: $figma-neutral-100;
    }

    .contact-list {
        max-height: 50vh;
        overflow-y: auto;
    }
}

.shared-popover {
    .popover-body {
        background-color: $figma-neutral-400 !important;
        color: $figma-shades-0 !important;
    }

    .popover-arrow::after {
        border-bottom-color: $figma-neutral-400 !important;
    }
}

#transfer-modal {
    .profile-picture {
        height: 41px;
        width: 41px;
        max-width: none;
    }

    .circular-icon-container {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .search-box-container {
        position: relative;

        .search-box {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            position: absolute;
            max-height: 250px;
            overflow-y: auto;
        }

        .line-height-66 {
            line-height: 66px;
        }
    }

    input {
        &.focused {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .result-row {
        border-bottom: 1px solid $figma-neutral-200;

        &:not(.disabled) {
            cursor: pointer;

            &:hover {
                background-color: $figma-neutral-100;
            }

            .title,
            .subtitle {
                color: $figma-neutral-600;
            }
        }

        &.disabled {
            .profile-picture {
                opacity: 0.4;
            }

            .title,
            .subtitle {
                color: $figma-neutral-400;
            }
        }
    }

    .remove-icon-container {
        width: 54px;

        svg {
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            color: $figma-primary-600;

            svg {
                transform: scale(1.3);
            }
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }
}

.shared-popover {
    .popover-body {
        background-color: $figma-neutral-400 !important;
        color: $figma-shades-0 !important;
    }

    .popover-arrow::after {
        border-bottom-color: $figma-neutral-400 !important;
    }
}

#case-modal {
    padding: 0;
    overflow-y: hidden;

    .btn {
        border-width: 2px;
        padding: 11px 16px;
    }

    .btn-outline-light:not(:hover) {
        background-color: rgba($color: $figma-darkmode-900, $alpha: 0.5)
    }

    .square-icon-btn {
        padding: 6px;
    }


    .control-panel-button {
        position: absolute;
        right: 48px;
        bottom: 48px;
    }

    .control-panel {
        position: absolute;
        width: 450px;
        right: 0;
        bottom: 0;
        background-color: $figma-darkmode-800;
        border-radius: 5px 0 0 0;

        .control-panel-header {
            border-radius: 5px 0 0 0;
            color: $figma-neutral-100;
            background-color: $figma-darkmode-600;
            border-radius: 5px;
        }

        .layer-list {
            max-height: 50vh;
            overflow-y: auto;

            .layer-block {
                padding: 24px 24px 0 24px;

                &:not(:last-child) {
                    border-bottom: 1px solid $figma-darkmode-500;
                    padding-bottom: 24px;
                }

                .opacity-number {
                    width: 36px;
                }

                .eye-container {
                    line-height: 20px;
                    height: 36px;
                    width: 36px;
                    border: 1px solid $figma-neutral-300;
                    border-radius: 5px;
                    display: flex;
                    flex-shrink: 0;
                    align-items: center;
                    justify-content: center;

                    .eye-icon {
                        color: $figma-primary-500;
                    }
                }
            }
        }

    }

    .z-index-99 {
        z-index: 99;
    }

    .color-square {
        height: 25px;
        width: 25px;
        font-size: 10px;
        padding: 3px;
    }

    .top-left-panel {
        position: absolute;
        top: 24px;
        left: 48px;
        overflow: auto;

        .name-panel {
            padding: 16px;
            border-radius: 5px;
            background-color: $figma-darkmode-800;
            color: $figma-neutral-300;
        }

        .info-panel {
            max-width: 250px;
            margin-top: 24px;
            padding: 16px;
            border-radius: 5px;
            background-color: $figma-darkmode-800;
            color: $figma-neutral-50;
            word-break: break-all;

            .color-info {
                height: 18px;
                width: 18px;
            }
        }
    }

    .top-right-panel {
        position: absolute;
        top: 48px;
        right: 48px;
        overflow: auto;
        border-radius: 5px;
    }

    .reset-position-button {
        position: absolute;
        bottom: 48px;
        left: 48px;
        overflow: auto;
    }

    .zoom-panel-container {
        width: 100%;
        position: absolute;
        bottom: 48px;
        overflow: auto;

        .zoom-panel {
            border-radius: 5px;
            color: $figma-neutral-50;
        }
    }

    .close-icon {
        cursor: pointer;
        color: $figma-neutral-600;
        position: absolute;
        top: 16px;
        right: 16px;
    }
}

.text-canvas-position {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
}