@import "../../static/scss/colors.scss";

.Sidebar {
    background-color: $figma-shades-0;
    width: 300px !important;
    border-radius: 0 16px 16px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    .nav-link {
        svg:not(.sidebar-chevron):not(.external-link) {
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            height: 24px;
            width: 24px;
        }
    }
    &.collapsed {
        .nav-link {
            svg:not(.sidebar-chevron):not(.external-link) {
                height: 34px;
                width: 34px;
            }
        }
    }
    .btn-primary {
        .nav-link {
            svg {
                height: 18px !important;
                width: 18px !important;
            }
        }
    }
    &.collapsed {
        .btn-primary{
            .nav-link {
                svg {
                    height: 24px !important;
                    width: 24px !important;
                }
            }
        }
    }

    .nav-link:not(.nav-link-button):not(.profile-link)::before {
        -webkit-transition: all 0.5s ease-in-out, color 0.05s ease-in-out;
        -moz-transition: all 0.5s ease-in-out, color 0.05s ease-in-out;
        -ms-transition: all 0.5s ease-in-out, color 0.05s ease-in-out;
        transition: all 0.5s ease-in-out, color 0.05s ease-in-out;
        content: "";
        background-color: $figma-secondary-300;
        position: absolute;
        left: -24px;
        height: 100%;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        width: 0;
    }
    &.collapsed{
        .nav-link {
            -webkit-transition: all 0.5s ease-in-out, color 0.05s ease-in-out;
            -moz-transition: all 0.5s ease-in-out, color 0.05s ease-in-out;
            -ms-transition: all 0.5s ease-in-out, color 0.05s ease-in-out;
            transition: all 0.5s ease-in-out, color 0.05s ease-in-out;
        }
        .nav-link.active:not(.nav-link-button) {
            position: relative;
            background-color: unset !important;
            color: $figma-secondary-300 !important;
        }
        .nav-link.active:not(.nav-link-button)::before {
            width: 7px;
        }
    }

    &>div>div:not(.sidebar-arrow-container) {
        overflow: hidden;
    }

    .creation-button-container {
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    &.collapsed {
        .creation-button-container {
            padding-left: 21px !important;
            padding-right: 21px !important;
        }
    }

    .profile-picture-container {
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        margin-right: 20px;
    }
    &.collapsed  {
        .profile-picture-container {
            margin-right: 0;
        }
    }

    .profile-text-container div {
        -webkit-transition: all 0.2s ease-in-out 0.3s;
        -moz-transition: all 0.2s ease-in-out 0.3s;
        -ms-transition: all 0.2s ease-in-out 0.3s;
        transition: all 0.2s ease-in-out 0.3s;
        opacity: 1;
    }
    &.collapsed  {
        .profile-text-container div {
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            font-size: 0pt;
        }
    }

    &:not(.collapsed) {
        .nav-item {
            span,
            .submenu-chevron {
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
                opacity: 1;
            }
        }
    }

    &.collapsed {
        .divider-container {
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            padding-left: 17px !important;
            padding-right: 17px !important;
        }   
    }

    .nav-item {
        a {
            -webkit-transition: all 0.5s ease-in-out, background-color 0.5s ease-in-out 0.05s, color 0.05s ease-in-out;
            -moz-transition: all 0.5s ease-in-out, background-color 0.5s ease-in-out 0.05s, color 0.05s ease-in-out;
            -ms-transition: all 0.5s ease-in-out, background-color 0.5s ease-in-out 0.05s, color 0.05s ease-in-out;
            transition: all 0.5s ease-in-out, background-color 0.5s ease-in-out 0.05s, color 0.05s ease-in-out;
        }
    }

    &.collapsed {
        width: 82px !important;

        .nav-item {
            a {
                padding: 0;
            }

            &.creation-button {
                a {
                    -webkit-transition: all 0.5s ease-in-out;
                    -moz-transition: all 0.5s ease-in-out;
                    -ms-transition: all 0.5s ease-in-out;
                    transition: all 0.5s ease-in-out;
                    padding: 8px;
                }
                svg {
                    -webkit-transition: all 0.5s ease-in-out;
                    -moz-transition: all 0.5s ease-in-out;
                    -ms-transition: all 0.5s ease-in-out;
                    transition: all 0.5s ease-in-out;
                    margin-right: 0;
                }
            }

            span,
            .submenu-chevron {
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
                opacity: 0;
                font-size: 0pt;
            }
        }
    }

    .verima-logo-container {
        min-height: 85px;
    }
    .verima-logo {
        margin-top: 40px;
        height: 45px;
        -webkit-transition: opacity 0.50s ease-in-out 0.25s;
        -moz-transition: opacity 0.50s ease-in-out 0.25s;
        -ms-transition: opacity 0.50s ease-in-out 0.25s;
        transition: opacity 0.50s ease-in-out 0.25s;
        opacity: 1;
        &.small {
            // -webkit-transition: opacity 0.50s ease-in-out;
            // -moz-transition: opacity 0.50s ease-in-out;
            // -ms-transition: opacity 0.50s ease-in-out;
            // transition: opacity 0.50s ease-in-out;
            opacity: 0;
            height: 0px;
        }
    }
    &.collapsed {
        .verima-logo {
            margin-top: 40px;
            height: 0px;
            // -webkit-transition: opacity 0.50s ease-in-out;
            // -moz-transition: opacity 0.50s ease-in-out;
            // -ms-transition: opacity 0.50s ease-in-out;
            // transition: opacity 0.50s ease-in-out;
            opacity: 0;
            &.small {
                // -webkit-transition: opacity 0.50s ease-in-out 0.25s;
                // -moz-transition: opacity 0.50s ease-in-out 0.25s;
                // -ms-transition: opacity 0.50s ease-in-out 0.25s;
                // transition: opacity 0.50s ease-in-out 0.25s;
                opacity: 1;
                width: 45px;
                height: auto;
            }
        }
    }

    .sidebar-arrow-container {
        padding-top: 4px;
        position: relative;
        height: 32px;

        .sidebar-arrow {
            background-color: $figma-shades-0;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
            position: absolute;
            right: -16px;
            height: 32px;
            width: 32px;
            border-radius: 8px;
            z-index: 99;

            .sidebar-chevron {
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
                transform: rotate(0deg);

                &.closed {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &.collapsed {
        hr {
            margin: 7px 0;
        }
    }
    hr {
        margin: 4px 0;
    }

    &.collapsed {
        .nav-pills {
            li {
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
                margin-top: 32px;
            }
        }
    }

    .nav-pills {
        li {
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            margin-top: 16px;
        }

        .link-dark {
            color: $figma-neutral-500;
        }

        .nav-link.active {
            color: $figma-primary-600;
            background-color: $figma-secondary-300;
        }
        .nav-link-button.active {
            color: unset;
            background-color: unset;
        }
        .submenu-navlink.active {
            color: $figma-neutral-500;
            background-color: unset;
        }

        .submenu-chevron {
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            transform: rotate(-90deg);

            &.closed {
                transform: rotate(0deg);
            }
        }

        .submenu {
            border-left: solid 2px $figma-secondary-300;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            height: 96px;
            overflow: hidden;

            &.hide-my-cases {
                height: 48px;
            }

            &.closed {
                height: 0px;
            }

            li {
                margin-top: 8px;
            }
        }
    }

    .creation-button {
        margin-top: 20px !important;

        svg {
            margin-right: 12px;
        }
    }

    .profile-container {
        background-color: $figma-primary-100;
        border-bottom-right-radius: 16px;

        .profile-picture {
            height: 32px;
            width: 32px;
            max-width: none;
        }
    }

    .unread-notifications-dot {
        content: "";
        background-color: $figma-primary-600;
        // position: absolute;
        // left: -24px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        top: 0;
        right: 9px;
    }

}

#logout-modal {
    a {
        text-decoration: none;
        color: $figma-primary-600;
        font-weight: bold;
        .card {
            background-color: $figma-secondary-300;
            height: 200px;
        }
    }
    .modal-icon {
        height: 48px;
        width: 48px;
        border-radius: 50%;
    }
}