@import "../../static/scss/colors.scss";


#create-case-modal {
    .custom-case-col {
      border-left: 2px solid $figma-neutral-300;
    }

    .custom-h-222 {
      height: 222px;
    }

    .custom-top-8 {
      top: 8px;
    }
}

.Dashboard {
    .box {
        display: flex;
        flex-flow: column;
        height: 100%;
        overflow: auto;
      }
      
      .box .header {
        z-index: 0;
        flex: 0 1 auto;
        padding-left: 47px;
        padding-right: 30px;
        max-height: 300px;
        opacity: 1;
        transition: all 1s ease-in-out;
        &.hidden {
          max-height: 0px;
          opacity: 0;
          transition: all 1s ease-in-out;
        }
      }
      
      .box .content {
        z-index: 1;
        flex: 1 1 auto;
        overflow: auto;
      }
}