@import "../../static/scss/colors.scss";


.CreateCase {
    background-color: $figma-shades-0;
    $margin: 30px;
    $margin-left: $margin + 17;
    margin: $margin;
    margin-left: $margin-left;
    height: calc(100% - (2 * $margin));
    border-radius: 16px;

    form {
        overflow-x: auto;
    }

    .caseColumn {
        height: 100%;
        overflow-y: auto;
        border-right: 1px solid $figma-neutral-200;
    }

    .nonfield-errors {
        color: $figma-error-500;
        font-size: .875em;
    }

    input[fieldtype=opacity] {
        min-width: 75px;
    }

    .remove-layer-button {
        height: 37px;
        width: 37px;
        margin-bottom: 4px;
    }

    .new-layer-button {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 40%;
        min-width: 150px;
        max-width: 250px;
        svg {
            margin-right: 12px;
        }
    }

    .spinner-border {
        width: 64px;
        height: 64px;
    }

    .progressbar-container {
        height: 68px;
    }

    #delete-case-modal {
        a {
            text-decoration: none;
            color: $figma-primary-600;
            font-weight: bold;
            .card {
                background-color: $figma-secondary-300;
                height: 200px;
            }
        }
        .trash-circle {
            height: 48px;
            width: 48px;
            border-radius: 50%;
        }
    }
}