@import "../../../static/scss/colors.scss";


.NotificationsTable {
    overflow-y: auto;

    .unread-dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $figma-secondary-300;
        position: absolute;
        top: 7px;
        left: -14px;
    }

    .notification-category-title {
        border-bottom: 1px solid $figma-neutral-400;
    }

    .notification-row {
        height: 60px;
        border-radius: 4px;
        &:hover {
            background-color: $figma-neutral-100;
            cursor: pointer;
        }
    }

    .delete-notification-icon-container {
        width: 54px;
        svg {
            transition: all 0.2s ease-in-out;
        }
        &:hover {
            color:  $figma-primary-600;
            svg {
                transform: scale(1.3);
            }
        }
    }

}