@import "../../../static/scss/colors.scss";

.SideTextureTemplate {
    .texture-container {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .nonfield-errors {
        color: $figma-error-500;
        font-size: .875em;
        p {
            text-align: center;
        }
    }

    .password-icon-container {
        cursor: pointer;
        margin-left: -44px;
        padding: 10px;
        .password-icon {
            color: $figma-primary-600;
        }
    }

    .password-input {
        &::-ms-reveal {
            display: none;
        }
    }

    .verima-logo {
        height: 50px !important;
    }
    
    .witapp-logo {
        height: 21px !important;
    }

    .main-container {
        max-width: 650px;
    }
}

#quit-modal {
    .alert-circle {
        height: 48px;
        width: 48px;
        border-radius: 50%;
    }
}