$figma-neutral-50: #F8FAFC;
$figma-neutral-100: #F1F5F9;
$figma-neutral-200: #E2E8F0;
$figma-neutral-300: #CBD5E1;
$figma-neutral-400: #94A3B8;
$figma-neutral-500: #64748B;
$figma-neutral-600: #475569;
$figma-neutral-700: #334155;
$figma-neutral-800: #1E293B;
$figma-neutral-900: #0F172A;

$figma-primary-50: #DDEAEF;
$figma-primary-100: #BAD6DE;
$figma-primary-200: #98C1CE;
$figma-primary-300: #76ACBD;
$figma-primary-400: #5497AD;
$figma-primary-500: #31839C;
$figma-primary-600: #0F6E8C;
$figma-primary-700: #0A495D;
$figma-primary-800: #083746;
$figma-primary-900: #05252F;

$figma-secondary-50: #DFF9F2;
$figma-secondary-100: #C0F3E6;
$figma-secondary-200: #A0EED9;
$figma-secondary-300: #80E8CC;
$figma-secondary-400: #6EC7AF;
$figma-secondary-500: #5BA692;
$figma-secondary-600: #498575;
$figma-secondary-700: #376357;
$figma-secondary-800: #25423A;
$figma-secondary-900: #12211D;

$figma-success-50: #ECFDF5;
$figma-success-100: #D1FAE5;
$figma-success-200: #A7F3D0;
$figma-success-300: #6EE7B7;
$figma-success-400: #34D399;
$figma-success-500: #10B981;
$figma-success-600: #059669;
$figma-success-700: #047857;
$figma-success-800: #065F46;
$figma-success-900: #064E3B;

$figma-warning-50: #FFFBEB;
$figma-warning-100: #FEF3C7;
$figma-warning-200: #FDE68A;
$figma-warning-300: #FCD34D;
$figma-warning-400: #FBBF24;
$figma-warning-500: #F59E0B;
$figma-warning-600: #D97706;
$figma-warning-700: #B45309;
$figma-warning-800: #92400E;
$figma-warning-900: #78350F;

$figma-error-50: #FEF2F2;
$figma-error-100: #FEE2E2;
$figma-error-200: #FECACA;
$figma-error-300: #FCA5A5;
$figma-error-400: #F87171;
$figma-error-500: #EF4444;
$figma-error-600: #DC2626;
$figma-error-700: #B91C1C;
$figma-error-800: #991B1B;
$figma-error-900: #7F1D1D;

$figma-shades-0: #FFFFFF;
$figma-shades-100: #000000;

$figma-darkmode-50: #5F626B;
$figma-darkmode-100: #555962;
$figma-darkmode-200: #4C4F59;
$figma-darkmode-300: #424651;
$figma-darkmode-400: #424651;
$figma-darkmode-500: #393D48;
$figma-darkmode-600: #2F343F;
$figma-darkmode-700: #262A36;
$figma-darkmode-800: #1C212E;
$figma-darkmode-900: #131825;

.neutral-50 {
    color: $figma-neutral-50;
}

.neutral-200 {
    color: $figma-neutral-200;
}

.neutral-300 {
    color: $figma-neutral-300;
}

.neutral-400 {
    color: $figma-neutral-400;
}

.neutral-500 {
    color: $figma-neutral-500;
}

.neutral-600 {
    color: $figma-neutral-600;
}

.neutral-700 {
    color: $figma-neutral-700;
}

.neutral-800 {
    color: $figma-neutral-800;
}

.neutral-900 {
    color: $figma-neutral-900;
}

.primary-600 {
    color: $figma-primary-600;
}

.primary-800 {
    color: $figma-primary-800;
}

.secondary-300 {
    color: $figma-secondary-300;
}

.error-500 {
    color: $figma-error-500;
}
.error-600 {
    color: $figma-error-600;
}

.shades-0 {
    color: $figma-shades-0;
}

.bg-shades-0 {
    background-color: $figma-shades-0;
}

.bg-neutral-50 {
    background-color: $figma-neutral-50;
}

.bg-neutral-200 {
    background-color: $figma-neutral-200;
}

.bg-primary-100 {
    background-color: $figma-primary-100;
}

.bg-secondary-100 {
    background-color: $figma-secondary-100;
}
.bg-secondary-300 {
    background-color: $figma-secondary-300;
}