@import "../../static/scss/colors.scss";

.LicenseExpired {
    background-color: $figma-primary-800;

    .elements-container {
        width: 27%;
        min-width: 466px;
        max-width: 800px;
        max-height: 100%;

        .verima-logo {
            width: 174px
        }
    }
}