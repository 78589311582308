@import "../../../static/scss/colors.scss";

#downgrade-modal {
    a {
        text-decoration: none;
        color: $figma-primary-600;
        font-weight: bold;
        .card {
            background-color: $figma-secondary-300;
            height: 200px;
        }
    }
    .alert-icon {
        height: 48px;
        width: 48px;
        border-radius: 50%;
    }
}