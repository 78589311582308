@import "../../../static/scss/colors.scss";


.TransferTeamMembersTable {

    .subtext {
        margin-left: 28px;
    }

    & + .paginator {
        margin-bottom: -48px;
    }
}