@import "custom-bootstrap";
@import "custom-react-toastify";

html, body, #root {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    height: 100%;
    background-color: $figma-neutral-100;
    color: red;  // highlights unset colors on the website, TODO: REMOVE IN PRODUCTION
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
}

a.not-underlined, .btn-link.not-underlined {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

a.never-underlined, .btn-link.never-underlined {
    text-decoration: none;
}

.figma-h2-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 47px;
    letter-spacing: -0.02em;
}
.figma-h3-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -0.02em;
}
.figma-h4-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.02em;
}
.figma-h4-small-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.02em;
}
.figma-h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
}
.figma-h5-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.02em;
}
.figma-h5-small-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
}
.figma-h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
}
.figma-h6-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
}
.figma-h6-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
}
.figma-p1 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}
.figma-p1-medium {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}
.figma-p1-medium-underlined {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}
.figma-p1-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}
.figma-p1-italic {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}
.figma-p2-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.figma-p2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.figma-p3 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}
.figma-p3-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.figma-caption {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
.figma-caption-semibold {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.form-label {
    @extend .figma-p1;
    color: $figma-neutral-600;
}
input.form-control, textarea.form-control {
    color: $figma-neutral-800 !important;
}
.form-control::placeholder {
    color: $figma-neutral-400 !important;
}

.form-control:focus, .form-select:focus {
    border-color: $figma-primary-600;
    box-shadow: 0 0 0 0.1rem #{$figma-primary-600}40;
}
.btn-primary:disabled, .btn-primary.disabled {
    color: $figma-neutral-400;
    background-color: $figma-neutral-200;
    border-color: $figma-neutral-200;
    opacity: 1;
}

.form-control[type=text], .form-control[type=email], .form-control[type=password], .form-control[type=number] {
    font-size: 14px;
    line-height: 18px;
    padding: 12px 15px 12px 15px;
    &.form-control-sm {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &.form-control-lg {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &.dark-mode {
        background-color: $figma-darkmode-700;
        color: $figma-shades-0 !important;
        border-color: $figma-darkmode-600;
    }
}

.form-select {
    font-size: 14px;
    line-height: 18px;
    padding: 12px 15px 12px 15px;
    &.form-select-sm {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &.form-select-lg {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    &.dark-mode {
        // TODO
    }
}

.form-control[type=file] {
    font-size: 14px;
    line-height: 30px;
    padding: 6px 12px 6px 12px;
    &.form-control-sm {
        line-height: 26px;
        padding: 4px 8px 4px 8px;
    }
    &.form-control-lg {
        line-height: 34px;
        padding: 8px 16px 8px 16px;
    }
    &.dark-mode {
        // TODO
    }
}

.form-control[type=color] {
    height: 44px;
    width: 44px;
    font-size: 19px;
    border-radius: 5px;
    &.form-control-sm {
        height: 36px;
        width: 36px;
        font-size: 16px;
        padding: 4px;
    }
    &.form-control-lg {
        height: 52px;
        width: 52px;
        font-size: 22px;
        padding: 8px 6px;
    }
    &.dark-mode {
        border-color: $figma-darkmode-500;
        background: $figma-darkmode-700;
    }
}

.form-range {
    &.dark-mode {
        &::-webkit-slider-runnable-track {
            background-color: $figma-darkmode-300;
        }
        &::-moz-range-track {
            background-color: $figma-darkmode-300;
        }
        &::-ms-track {
            background-color: $figma-darkmode-300;
        }
    }
}

.btn {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    padding: 12px 17px;
    
    &.btn-lg {
        padding: 16px 19px;
    }

    &.btn-sm {
        font-size: 12px;
        line-height: 16px;
        padding: 9px 11px;
    }
}



.btn-outline-primary:disabled {
    background-color: $figma-neutral-100;
    color: $figma-neutral-400;
    border-color: $figma-neutral-400;
}

.btn-light, .btn-outline-light:hover {
    color: $figma-primary-600 !important;
}

.btn-link-error, .btn-link-error:hover {
    color: $figma-error-500;
}

.progress {
    height: 1.5rem;
    border-radius: 2rem;
    background-color: $figma-neutral-300;
    .progress-bar {
        color: $figma-primary-600;
        border-radius: 2rem;
        font-weight: 600;
    }
}

.alert-dismissible {
    padding-left: 1.5rem;
    border-color: transparent;
    color: $figma-neutral-600;

    .btn-close {
        right: 14px;
    }
    svg {
        margin-right: 12px;
    }
    .figma-p1 {
        margin-left: 32px;
    }

    &.alert-success {
        background-color: $figma-success-100;
        .alert-heading {
            color: $figma-success-700
        }
    }
    
    &.alert-danger {
        background-color: $figma-error-100;
        .alert-heading {
            color: $figma-error-600
        }
    }
    
    &.alert-warning {
        background-color: $figma-warning-100;
        .alert-heading {
            color: $figma-warning-600
        }
    }
    
    &.alert-info {
        background-color: $figma-primary-100;
        .alert-heading {
            color: $figma-primary-600
        }
    }
}

.form-check-container {
    padding-left: 0 !important;

    .form-check-input {
        flex-shrink: 0;
    }

    .form-check-label {
        margin-left: 0.5rem;
    }

    .form-check-input, .form-check-label {
        cursor: pointer;
    }

    .form-check-input:disabled+.form-check-label{
        cursor: auto;
    }

    .form-check-input {
        &[type=checkbox], &[type=radio] {
            font-size: 1.4em;
            /* Add if not using autoprefixer */
            -webkit-appearance: none;
            /* Remove most all native input styles */
            appearance: none;
            /* For iOS < 15 */
            background-color: #FAFAFA;
            /* Not removed via appearance */
            margin: 0;
    
            // color: $figma-primary-600;
            width: 1em;
            height: 1em;
            border: 0.12em solid $figma-neutral-600;
            
            border-radius: 15%;
            &[type=radio]{
                border-radius: 50%;
            }
    
            display: grid;
            place-content: center;
    
            &::before {
                content: "";
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                background-color: $figma-primary-600;
            }

            &:checked {
                &::before {
                    transform: scale(1);
                }
            }
        }

        &[type=checkbox]::before {
            width: 0.63em;
            height: 0.63em;
            border-radius: 1px;
        }

        &[type=radio]::before {
            width: 0.53em;
            height: 0.53em;
            border-radius: 50%;
        }
    }
}

.horizontal-line {
    width: 100%;
    border-top: 1px solid $figma-neutral-400;
}

.light-horizontal-line {
    @extend .horizontal-line;
    border-color: $figma-neutral-200;
}

.cursor-pointer {
    cursor: pointer;
}

.underlined {
    text-decoration: underline;
}

.enterprise-toast-container {
    width: 800px;
}

.form-check .form-check-input{
    background-image: none !important;
}

.form-switch .form-check-input{
    background-image: url("/static/images/switch-circle.svg") !important;
    &:checked {
        background-image: url("/static/images/switch-circle-checked.svg") !important;
    }
}

.form-select {
    background-image: url("/static/images/form-select-arrow.svg") !important;
}

.btn-close {
    background: transparent url("/static/images/btn-close.svg") center/1em auto no-repeat;
}