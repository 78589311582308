@import "../../static/scss/colors.scss";


.Profile {
    background-color: $figma-shades-0;
    $margin: 30px;
    $margin-left: $margin +17;
    margin: $margin;
    margin-left: $margin-left;
    height: calc(100% - (2 * $margin));
    border-radius: 16px;

    .available-custom-segmentation-key {
        width: 140px;
    }

    .nonfield-errors {
        color: $figma-error-500;
        font-size: .875em;
    }

    .progressbar-container {
        height: 68px;
    }

    .main-row {
        overflow-x: auto;
    }

    form {
        overflow-x: auto;
    }

    .leftColumn {
        height: 100%;
        overflow-y: auto;
        border-right: 1px solid $figma-neutral-200;
        min-width: 380px;

        .profile-container {
            border-bottom: 1px solid $figma-neutral-200;

            .profile-picture {
                height: 48px;
                width: 48px;
                max-width: none;
            }

            .profile-text-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
            }
        }

        .submenu {
            border-bottom: 1px solid $figma-neutral-200;
            cursor: pointer;
            height: 60px;
            position: relative;
            color: $figma-primary-600;

            &.active::before {
                width: 7px;
                content: "";
                background-color: $figma-primary-600;
                position: absolute;
                height: 100%;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            svg,
            .icon {
                margin-left: 25px;
                margin-right: 16px;
                height: 24px;
                width: 24px;
            }
        }
    }

    .rightColumn {
        height: 100%;
        overflow-y: auto;
        min-width: 370px;
        display: flex;
        flex-direction: column;

        .subsection-header {
            background-color: $figma-primary-600;
            border-top-right-radius: 16px;
            line-height: 33px;
        }

        .banner {
            max-height: 500px;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;

            &.hidden {
                max-height: 0;
            }

            .close-banner-icon {
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                &:hover {
                    transform: scale(1.3);
                }
            }
        }

        .subsection-content {
            flex-grow: 1;
            overflow: auto;

            .container-fluid {
                overflow-x: auto;
            }

            .AccountSummary {
                $box-size: 550px;
                min-width: $box-size;

                .enterprise-card {
                    width: $box-size;
                    background-color: $figma-neutral-50;
                    border: 1px solid $figma-primary-600;
                    padding: 16px;
                    border-radius: 10px;
                }
                
                .bottomContainer {
                    border-top: 1px solid $figma-neutral-400;
                    width: $box-size;

                    .subtext {
                        margin-left: 40px;
                    }

                    .stl-counter {
                        &.basic {
                            color: $figma-primary-600;
                        }

                        &.warning {
                            color: $figma-warning-500;
                        }

                        &.full {
                            color: $figma-error-600;
                        }
                    }

                    .progress {
                        height: 12px;
                    }

                    .progress-number {
                        width: 31px;
                        text-align: center;
                    }
                }
            }

            .PersonalInformations {
                $box-size: 550px;
                min-width: $box-size;

                .short-container {
                    width: $box-size;
                }

                .password-input {
                    &::-ms-reveal {
                        display: none;
                    }
                }

                .password-icon-container {
                    cursor: pointer;
                    margin-left: -44px;
                    padding: 10px;
                    .password-icon {
                        color: $figma-primary-600;
                    }
                }
            }

            .HelpCenter {
                .faq-container {
                    border: 1px solid $figma-neutral-100;
                    border-radius: 5px;
                    #faq-chevron {
                        transition: transform 0.5s ease-in-out;
                        transform: rotate(0deg);
                    }
                    .faq-body {
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.4s ease;
                    }
                    &.active {
                        #faq-chevron {
                            transform: rotate(180deg);
                        }
                        .faq-body {
                            max-height: 5000px;
                            transition: max-height 4s ease;
                        }
                    }
                }

                .help-center-link  {
                    width: 210px;
                }
                .help-center-flag  {
                    height: 15px;
                    // width: 30px;
                }
            }

            .DemoCases {
                #card-accordion-chevron {
                    transition: all 0.5s ease-in-out;
                    transform: rotate(0deg);
                    &.opened {
                        transform: rotate(180deg);
                    }
                }
                #card-accordion {
                    overflow: hidden;
                    max-height: 0px;
                    transition: max-height 0.4s ease;
                    &.opened {
                        max-height: 5000px;
                        transition: max-height 3s ease;
                    }
                }
                .preview-image-container {
                    width: 132px;
                    height: 76px;
                }
                .check-circle-container {
                    width: 76px;
                }
                .demo-case-card {
                    border-color: $figma-neutral-100;
                }
            }

            .License {
                &.tight-column {
                    width: 550px;
                }

                #license-accordion-chevron {
                    transition: all 0.5s ease-in-out;
                    transform: rotate(0deg);
                    &.opened {
                        transform: rotate(180deg);
                    }
                }
                #license-accordion {
                    overflow: hidden;
                    transition: all 0.5s ease;
                    max-height: 0px;
                    &.opened {
                        max-height: 550px;
                    }
                }
                .size-container {
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                    position: relative;
                    .size-content {
                        border: 1px solid $figma-neutral-400;
                        border-radius: 4px;
                    }
                    &.active {
                        display: block;
                        pointer-events: none;
                        .size-content {
                            opacity: 0.5;
                        }
                        &::after {
                            opacity: 1;
                            background-color: $figma-secondary-300;
                            color: $figma-primary-600;
                            border-radius: 0.25rem;
                            padding-left: 0.5rem;
                            padding-right: 0.5rem;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            position: absolute;
                            right: 0.5rem;
                            top: 0.5rem;
                            content: 'Current license';
                        }
                    }
                }
            }

            #downgrade-modal {
                a {
                    text-decoration: none;
                    color: $figma-primary-600;
                    font-weight: bold;
                    .card {
                        background-color: $figma-secondary-300;
                        height: 200px;
                    }
                }
                .alert-icon {
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                }
            }

            .CustomSegmentations {
                .details-link {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .subtext {
                    margin-left: 40px;
                }

                .Table {
                    overflow-y: unset;
                    .paginator {
                        margin-bottom: -48px;
                    }
                }
            }
        }
    }
}